import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MustBeLoggedGuard} from "./common/must-be-logged-guard.service";
import {MustNotBeLoggedGuard} from "./common/must-not-be-logged-guard.service";

const routes: Routes = [
  {path: 'login', loadChildren: './auth/login/login.module#LoginPageModule', canActivate: [MustNotBeLoggedGuard]},
  {path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [MustBeLoggedGuard]},
  {path: 'additional-products', loadChildren: './additional-products/products.module#ProductsModule', canActivate: [MustBeLoggedGuard]},
  {path: 'surveys', loadChildren: './surveys/surveys.module#SurveysModule', canActivate: [MustBeLoggedGuard]},
  {path: 'promos', loadChildren: './promos/promos.module#PromosModule', canActivate: [MustBeLoggedGuard]},
  {path: 'projects', loadChildren: './projects/projects.module#ProjectsPageModule', canActivate: [MustBeLoggedGuard]},
  {path: 'interviews', loadChildren: './interviews/interviews.module#InterviewsPageModule', canActivate: [MustBeLoggedGuard]},
  {path: 'products-catalogue',  loadChildren: './products-catalogue/products.module#ProductsPageModule', canActivate: [MustBeLoggedGuard]},
  {path: 'coupon', loadChildren: './coupon/coupon.module#CouponPageModule', canActivate: [MustBeLoggedGuard]},
  {path: 'accessories', loadChildren: './accessories/accessories.module#AccessoriesModule', canActivate: [MustBeLoggedGuard]},
  {path: 'accessory-categories', loadChildren: './accessory-categories/categories.module#CategoriesModule', canActivate: [MustBeLoggedGuard]},
  {path: 'accessory-typologies', loadChildren: './accessory-typologies/typologies.module#TypologiesModule', canActivate: [MustBeLoggedGuard]},
  {path: '', loadChildren: './splash/splash.module#SplashPageModule'},
  {path: '**', redirectTo: 'login', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
