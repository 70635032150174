import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {UtilsService} from './utils.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingBannerService} from "./loading-banner.service";
import {ActionsComponent} from "./actions/actions.component";
import {RegionsComponent} from "./regions/regions.component";
import {MultiSelectComponent} from "./dialog/multi-select.component";
import {SingleSelectComponent} from "./dialog/single-select.component";


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [ActionsComponent, RegionsComponent, MultiSelectComponent, SingleSelectComponent],
    exports: [RegionsComponent, MultiSelectComponent, SingleSelectComponent],
    entryComponents: [ActionsComponent, RegionsComponent, MultiSelectComponent, SingleSelectComponent],
    providers: [UtilsService, LoadingBannerService],

})
export class PgCommonModule {
}
