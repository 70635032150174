import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InterceptorService } from './common/interceptor.service';
import { NetworkService } from './common/network.service';
import { deferredAddToHomePrompt, restartBrowserApp, PgServiceWorkerService } from "./common/pg-service-worker.service";
import { MenuController, NavController, Platform } from "@ionic/angular";
import { AuthService } from "./auth/auth.service";
import { RepositoryService } from "./auth/repository.service";
import { UtilsService } from "./common/utils.service";
import { version } from '../../package.json';
export class AppComponent {
    constructor(platform, authService, authRepository, navigator, interceptorService, network, sw, util, menu) {
        this.platform = platform;
        this.authService = authService;
        this.authRepository = authRepository;
        this.navigator = navigator;
        this.interceptorService = interceptorService;
        this.network = network;
        this.sw = sw;
        this.util = util;
        this.menu = menu;
        this.selectedIndex = 0;
        this.subSelectedIndex = 0;
        this.subs = { interviews: false };
        this.appPages = [
            { title: 'Home', url: '/home/', icon: 'home' },
            {
                title: 'Prodotti',
                url: '',
                icon: 'cube',
                subKey: 'products',
                icon2: 'chevron-down',
                subMenu: [
                    { title: 'Catalogo', url: '/products-catalogue/', icon: 'list' },
                    { title: 'Coupon', url: '/coupon/', icon: 'barcode' }
                ]
            },
            { title: 'Progetti', url: '/projects/', icon: 'copy' },
            {
                title: 'Interviste',
                url: '',
                icon: 'list',
                subKey: 'interviews',
                icon2: 'chevron-down',
                subMenu: [
                    { title: 'Lista', url: '/interviews/list/', icon: 'list' },
                    { title: 'Anomalie Device', url: '/interviews/anomalous-device-codes-list/', icon: 'help' },
                    { title: 'Sondaggi', url: '/surveys/', icon: 'clipboard' },
                ]
            },
            {
                title: 'Gestione Accessori',
                url: '',
                icon: 'basket',
                subKey: 'accessories',
                icon2: 'chevron-down',
                subMenu: [
                    { title: 'Lista Accessori', url: '/accessories/', icon: 'list' },
                    { title: 'Tipologie', url: '/accessory-typologies/', icon: 'bookmark' },
                    { title: 'Categorie', url: '/accessory-categories/', icon: 'albums' },
                ]
            },
            { title: 'Logout', url: '/logout/', icon: 'log-out' }
        ];
        this.version = '';
        this.click = (url, i, type, submenu = [], key = '') => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedIndex = i;
            if (submenu.length == 0) {
                yield this.menu.toggle();
                if (type == 'menu') {
                    this.subSelectedIndex = -1;
                    for (let s of Object.keys(this.subs)) {
                        this.subs[s] = false;
                    }
                    if (url == '/logout/') {
                        yield this.logout();
                    }
                }
                else {
                    this.subSelectedIndex = i;
                }
            }
            else {
                this.subs[key] = !this.subs[key];
            }
        });
        this.version = version;
        this.initializeApp();
    }
    initializeApp() {
        /*this.platform.ready().then(() => {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
        });*/
        this.network.initialize();
        this.interceptorService.initialize();
        this.sw.init();
    }
    ngOnInit() {
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.authRepository.logout();
                yield this.authService.cleanAuth();
                yield this.navigator.navigateRoot('login');
            }
            catch (e) {
                yield this.authService.cleanAuth();
                yield this.navigator.navigateRoot('login');
            }
            window.location.href = '/';
        });
    }
    doInstallPwa() {
        console.debug('doInstallPwa()');
        // noinspection TypeScriptValidateJSTypes
        deferredAddToHomePrompt.prompt();
    }
    doCheckUpdates() {
        this.util.showToast('Verifica disponibilità di aggiornamenti in background...\nL\'operazione potrebbe richiedere alcuni minuti.', {
            color: 'success',
            duration: 2000
        })
            .catch(console.error);
        this.sw.checkForUpdate();
    }
    doRefreshBrowser() {
        restartBrowserApp();
    }
}
